<template>
	<div class="section section--sm">
		<div class="container">

			<div class="section__head section__head--table">
				<div class="title title--section">Languages</div>
			</div>

			<div class="section__main">
				<v-table :headers="tabelHeaders"
						:items="list"
						:hasLoadList="loadingGetList">
					<tr v-for="(td, index) in list" :key="index">
						<td>{{td.code}}</td>
						<td>{{td.name}}</td>
					</tr>
				</v-table>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	import VTable from '@components/VTable';

	export default {
		name: 'contacts-index',
		components:{
			VTable
		},

		data(){
			return {
				tabelHeaders: [
					{key:'code', text: 'Code'},
					{key:'name', text: 'Language'},
				]
			}
		},

		computed:{
			...mapState({
				list: state => state.languages.list,
				loadingGetList: state => state.languages.loadingGetList
			})
		},

		created(){
			this.getList()
		},

		methods:{
			...mapActions({
				GET_LIST: 'languages/GET_LIST'
			}),

			getList(){
				this.GET_LIST();
			}
		}
	};
</script>

<style scoped lang=scss>

</style>
